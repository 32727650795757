// @flow

import { observable, runInAction, action } from 'mobx'
import { Workbox } from 'workbox-window'
import { log } from '@fontanus/logger'
import type { ToastData } from '../types'

export default class AppStore {
  @observable FacebookAppId: string | null = null
  @observable GoogleClientId: string | null = null
  @observable isPreview: boolean = true
  @observable history = null
  @observable isConnected: boolean = false
  @observable isUpdating: boolean = false
  @observable hasNewVersion: boolean = false
  // TODO
  environment: string = process.env.NODE_ENV === 'todo' ? 'local' : window.location.hostname.split('.')[0]
  build: string = process.env.BUILD_ID ? process.env.BUILD_ID : 'local'
  workbox: Workbox | null = null
  @observable notifications : Array<ToastData> = []

  constructor ({ config, events, analytics }) {
    this.analytics = analytics
    this.FacebookAppId = config.FacebookAppId
    this.GoogleClientId = config.GoogleClientId
    this.workbox = this.startWorker('/service_worker.js', {})
    this.reloadPage = this.reloadPage.bind(this)
    events.on('connected', () => {
      this.isConnected = true
    })
    events.on('reload', this.reloadPage, this)
    events.on('logout', this.reset, this)
    window.addEventListener('beforeinstallprompt', this.beforeAppInstall.bind(this))
    window.addEventListener('appinstalled', this.onAppInstall.bind(this))
    window.addEventListener('load', this.appOpened.bind(this))
  }

  @action reset () {
    this.notifications = []
  }

  appOpened () {
    if (window.navigator.standalone) {
      this.analytics.event('load', 'standalone')
    } else if (window.matchMedia('(display-mode: standalone)').matches) {
      this.analytics.event('load', 'standalone')
    } else {
      this.analytics.event('load', 'browser')
    }
  }

  beforeAppInstall (event) {
    // Prevent the mini-infobar from appearing on mobile
    // event.preventDefault()
    // Stash the event so it can be triggered later.
    // deferredPrompt = event
    // Update UI notify the user they can install the PWA
    this.analytics.event('pwa', 'beforeInstall')
  }

  onAppInstall (event) {
    this.analytics.event('pwa', 'install')
  }

  startWorker (swFile, options) {
    if ('serviceWorker' in navigator) {
      const wb = new Workbox(swFile, options)

      wb.addEventListener('installed', (event) => {
        if (this.isUpdating) {
          runInAction(() => { this.isUpdating = false })
        }
      })
      wb.addEventListener('waiting', (event) => {
        runInAction(() => { this.hasNewVersion = true })
      })

      wb.register()
        .then((registration) => {
          if (registration.installing) {
            runInAction(() => { this.isUpdating = true })
          }
        })
      return wb
    }
    return null
  }

  reloadPage () {
    if (this.workbox == null) {
      return window.location.reload()
    }

    return this.workbox.messageSW({ type: 'SKIP_WAITING' })
      .then(() => {
        log.debug('reloading page')
        window.location.reload()
      })
      .catch(err => {
        log.error('could not skip waiting', err)
        window.location.reload()
      })
  }

  displayNotification (notification: ToastData) {
    if (window.Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then(function (registration) {
        if (!registration) {
          this.notifications.push(notification)
        } else {
          registration.showNotification(notification.title, notification.options)
        }
      })
    } else {
      this.notifications.push(notification)
    }
  }
}
