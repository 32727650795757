import * as Sentry from '@sentry/browser'
import {log as logger} from '@fontanus/logger'
export default class MySentry {
  constructor ({ config, application }) {
    Sentry.init({
      dsn: config.sentry,
      release: application.build,
      environment: application.environment,
      beforeSend (event) {
        logger.trace('Sentry event', event)
        return event
      },
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          beacon: true, // Log HTTP requests done with the Beacon API
          console: false, // Log calls to `console.log`, `console.debug`, etc
          dom: true, // Log all click and keypress events
          fetch: true, // Log HTTP requests done with the Fetch API
          history: true, // Log calls to `history.pushState` and friends
          sentry: true, // Log whenever we send an event to the server
          xhr: true // Log HTTP requests done with the XHR API
        }),
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: true
        })
      ],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.'
      ],
      blacklistUrls: [
        // local development
        /localhost:1234/i,
        /local.castle-of-mind.com:1234/i,
        /127\.0\.0\.1:1234/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Ignore Google flakiness
        /\/(gtm|ga|analytics)\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Firefox extensions
        /^resource:\/\//i
      ]
    })
  }

  setUser (userId, email) {
    Sentry.setUser({
      id: userId,
      email: email
    })
  }

  logout () {
    Sentry.configureScope(scope => scope.clear())
  }

  withScope = Sentry.withScope
  captureException = Sentry.captureException
  showReportDialog = Sentry.showReportDialog
  addBreadcrumb = Sentry.addBreadcrumb
}
