// TODO
// if (process.env.NODE_ENV !== 'production') {
//   // monkey patching window.fetch
//   // see https://github.com/cypress-io/cypress/issues/95#issuecomment-442107615
//   delete window.fetch
//   require('whatwg-fetch')
// }
// TODO
const baseUrl = `https://e377116LcNPY.${process.env.NODE_ENV === 'production' ? 'live' : 'live'}.gamesparks.net/callback/e377116LcNPY`

export function requestPasswordResetLink(email) {
  return window.fetch(`${baseUrl}/password/UK7DwWYBfq2cFeFxzGmvM6muyKUlNwth/?email=${email}`)
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        const err = new Error()
        err.data = data.errors
        throw err
      }
      return data
    })
}

export function setNewPasswordWithResetCode({ code, password }) {
  return window.fetch(`${baseUrl}/password/UK7DwWYBfq2cFeFxzGmvM6muyKUlNwth/?code=${code}&password=${password}`)
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        const err = new Error()
        err.data = data.errors
        throw err
      }
      return data
    })
}

export function GsNonce(nonce) {
  return window.fetch(`${baseUrl}/nonce/fEol3LvZilqRqbsJi3EURKm59LsGZdA9/?nonce=${nonce}`)
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        const err = new Error()
        err.data = data.errors
        throw err
      }
      return data.nonce
    })
}
