import type * as log from 'loglevel'

let urlParams = new URLSearchParams(window.location.search.substring(1))
/**
 * Sets the params used for search query based setup
 * @param {URLSearchParams} params URL Search params to use (for mocking tests)
 */
const testSetParams = (params: URLSearchParams): void => {urlParams = params}  // this is for testing

type Context = Object
type ContextOrNull = Context | null
/**
 * Figures our the initial log context to use
 */
function initialContext(): () => ContextOrNull {
  return () => urlParams.get('lc') ? JSON.parse(decodeURIComponent(urlParams.get('lc'))) : null
}

/**
 * Returns the querystring needed for the current setup
 */
function getSetupString(): string {
  const url = new URLSearchParams('')
  if (_isRemote()) {
    url.append('lr', '1')
  }
  url.append('ll', localStorage.getItem('loglevel'))
  if (logContextGetter) {
    url.append('lc', encodeURIComponent(JSON.stringify(logContextGetter())))
  }
  return url.toString()
}

/**
 * Gets the default log level
 */
function getDefaultLogLevel(): log.LogLevelDesc {
  if(urlParams.get('ll')) {
    return urlParams.get('ll') as log.LogLevelDesc
  } else if(localStorage.getItem('loglevel')) {
    return localStorage.getItem('loglevel') as log.LogLevelDesc
  }
  return 'warn'
}

/**
 * Returns and saves the status of the current logger
 */
function _isRemote(): boolean {
  if(!sessionStorage.getItem('logger.remote')) {
    if(urlParams.get('lr')) {
      sessionStorage.setItem('logger.remote', '1')
    } else {
      sessionStorage.setItem('logger.remote', '0')
    }
  }
  return !!parseInt(sessionStorage.getItem('logger.remote'), 10)
}

/**
 * A Log record
 */
 interface Log {
   message: string;
   loggerName: string;
   logLevel: string;
   data?: object;
   context: Context;
   stack?: string;
 }

/**
 * A simple buffer of messages
 */
let messageQueue: Log[] = []
let isSending: boolean = false
let url: string | false = false
let defaultLogger: string = 'default'
let logContextGetter = initialContext()
/**
 * Saves a remote log for sending
 */
function remoteLog(
  message: string, 
  data: { [s: string]: any }, 
  loggerName: string, 
  logLevel: string, 
  isError: boolean
  ): void {
    if(!loggerName) {
      loggerName = defaultLogger
    }

    let context: object
    if(data && data.context) {
      context = data.context 
    } else {
      context = logContextGetter()
    }
    let log: Log = { message, loggerName, logLevel, context }
    if(data) {
      if(typeof data == 'string') {
        data = {
          details: data
        }
      }
      log.data = data
    }
    
    if (isError) {
      log.stack = new Error().stack
    }
    messageQueue.push(log)
    sendNextMessge()
}

/**
 * Sends the messages in messageQueue
 */
function sendNextMessge(): void {
  if (!messageQueue.length || isSending) {
    return
  }
  if (!url) {
    throw new Error('log.setupLogger should be called first')
  }
  
  isSending = true
  
  const msg = messageQueue.shift()
  const req = new window.XMLHttpRequest()
  
  req.open("POST", url, true)
  req.setRequestHeader('Content-Type', 'application/json')
  req.onreadystatechange = function() {
    if(req.readyState == 4) 
    {
      isSending = false
      setTimeout(sendNextMessge, 0)
    }
  }
  req.send(JSON.stringify(msg))
}

function setupLogger (
  _url: string, 
  _defaultLogger: string = 'default', 
  _logContextGetter: () => Context = initialContext()
  ): void {
    url = _url
    defaultLogger = _defaultLogger
    logContextGetter = _logContextGetter
}

/**
 * A logger plugin for remote logging
 */
const LoggerFactory = (originalLogFactory: log.MethodFactory, isRemote?: boolean): log.MethodFactory => {
  if(typeof isRemote === 'undefined') {
    isRemote = _isRemote()
  }
  return function logger(methodName: string, logLevel: log.LogLevelNumbers, loggerName: string) {
    const rawMethod = originalLogFactory(methodName, logLevel, loggerName);
   
    return function (message: string, data: object) {
      if(typeof message != 'undefined' && message) {
        if(isRemote) {
          remoteLog(message, data, loggerName, methodName, ['trace', 'error'].lastIndexOf(methodName) > -1 ? true : false)
        }
        rawMethod(message, data)
      }
    };
  }
}

export {getDefaultLogLevel, LoggerFactory, setupLogger, getSetupString, testSetParams}