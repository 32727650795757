import { log } from '@fontanus/logger'
import Composer from '../CompositionRoot'

export default class Logger {
  logger = log

  constructor ({ config, application, user }) {
    this.config = config
    this.application = application
    this.user = user
    log.setupLogger(config.logCollector, 'com-webapp', this.getLogContext.bind(this))
  }

  getLogContext () {
    const context = {
      user: this.user.user,
      isPreview: this.application.isPreview,
      version: this.application.version,
      build: this.application.build
    }
    return context
  }
}
