// @flow
import { observable, computed, action, autorun } from 'mobx'
import { Message } from './chat.store'
import type { MessageType } from './chat.store'
import type { Transport } from '../transport'

export default class ChallengeChatStore {
  @observable loading: boolean = true
  @observable open: boolean = false
  @observable lastRead: Date | null = null
  @observable messages: Array<Message> = []
  @observable challenge : any = null
  @observable currentUserId: string
  @observable challengeId: string
  transport: transport

  @computed get isReady () {
    return this.currentUserId && this.challengeId
  }

  constructor ({ transport, events }: {transport: Transport}) {
    this.transport = transport
    this.events = events
    this.sendMessage.bind(this)
  }

  @action setUp (currentUserId: string, challengeId: string) {
    this.currentUserId = currentUserId
    this.challengeId = challengeId
    this.events.on('ChallengeChatMessage', this.addMessage, this)
    autorun(() => {
      this.debug('this.messages', this.messages.toJS())
    })
  }

  @action setMessages (messages: Array<MessageType>, data: {
    [string]: {
      lastRead: Date
    }
  }) {
    if (!this.isReady) return

    this.loading = false
    this.open = true
    this.lastRead = data[this.currentUserId].lastRead
    messages.reverse()
    this.messages = messages.map(message => new Message(this.currentUserId, message))
  }

  @action addMessage (message: string) {
    if (!this.isReady) return

    this.messages.push(new Message(this.currentUserId, message))
  }

  sendMessage (message: {message: string}) {
    if (!this.isReady) return

    this.transport.sendChallengeChat(this.challengeId, message.message)
  }
}
