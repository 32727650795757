// @flow

import { observable, action, computed, values, set, toJS } from 'mobx'
import { mergeDeepLeft, partial, sort } from 'ramda'
import type { ToastData, MessageType } from '../types'

const TimestampSorter = partial(sort, [(m1, m2) => m2.options.timestamp - m1.options.timestamp])

export default class MessageStore {
  @observable _messages : {[string]: MessageType} = {}

  constructor ({ events, transport, user, application }) {
    this.events = events
    this.transport = transport
    this.user = user
    this.application = application
    events.on('login', this.loadMessages, this)
    events.on('logout', this.reset, this)
    events.on('notification', this.addNotification, this)
    this.markAllAsRead = this.markAllAsRead.bind(this)
  }

  @action reset () {
    this._messages = {}
    this.events.off('notification', this.addNotification, this)
  }

  @action addNotification = (notification: ToastData) => {
    if (!notification.options.data.showOnly) {
      this.application.displayNotification(notification)
    } else if (notification.options.data.showOnly && notification.options.data.showOnly == this.user.user.userId) {
      this.application.displayNotification(notification)
    }
    if (!notification.dismissedMessage) {
      this.addMessage(notification)
    }
  }

  @action addMessage = (message: ToastData) => {
    if (this._messages[message.options.tag]) {
      message = mergeDeepLeft(message, toJS(this._messages[message.options.tag]))
    }
    if (!message.options.timestamp) {
      message.options.timestamp = Date.now()
    }
    set(this._messages, { [message.options.tag]: message })
  }

  @computed get messages () :Array<ToastData> {
    return TimestampSorter(values(this._messages))
  }

  loadMessages () {
    this.transport.listMessages()
      .then(messages => {
        messages.forEach(this.addMessage)
      })
  }

  markAsRead (messageId) {
    this.transport.markMessageRead(messageId)
      .then(this.addMessage)
  }

  @computed get unreadMessages () {
    return this.messages.filter(m => m.options.data.status === 'unread')
  }

  markAllAsRead () {
    this.transport.markMessagesRead(this.unreadMessages.map(m => m.options.tag))
      .then(messages => {
        messages.forEach(this.addMessage)
      })
  }

  @computed get unreadMessageCount () {
    return this.unreadMessages.length
  }
}
