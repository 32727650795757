// @flow
import React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'
import { Button, Flex } from 'rebass'

const Wrapper = styled.div`
    position:relative;
    z-index: 1;
`

function HOCWrapper (PassedComponent) {
  return function WrapperWrappedComponent (props: any) {
    return (<Wrapper>
      <PassedComponent {...props} />
    </Wrapper>)
  }
}

const BaseButton = `
  cursor: pointer;
  font-variant: small-caps;
  align-items: center;
  position: relative;
  padding: 0.2em 0.5em;
  box-sizing: border-box;
  color: #fff;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 0.5em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -5px;
    border-radius: inherit;
  }

  &:focus {
    outline:0;
  }

  &:active {
    top: 2px;
  }
`

const GradientBorderButton = styled(Button)`
  ${color}
  ${BaseButton}
`
GradientBorderButton.defaultProps = {
  bg: 'transparent',
  as: 'button'
}

const ButtonLabel = styled.div`
  ${color}
  ${BaseButton}
`
ButtonLabel.defaultProps = {
  bg: 'transparent',
  as: 'div'
}

const CircleButton = styled(Button)`
  ${BaseButton}
  align-items: center;
  margin: auto;
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  color: #fff;
  background: black;
  background-clip: padding-box;
  border: solid 5px transparent;
  border-radius: 1.5em;
  &:hover {
    &:after {
      background-color: white
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -4px;
    border-radius: inherit;
    border: solid 1px #000000ff;
    background-color: black;
    box-shadow: inset 9px 9px 6px -10px #ffffff, 1px 1px 4px 0px #000000;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 8px;
    border-radius: inherit;
    background-color: red;
  }
  &:focus {
    outline:0;
  }
  &:active {
    top: 2px;
  }
`

const CircleButtonWrapper = styled(Flex)`
  ${BaseButton}
  color: black;
  z-index: 1;
`

function ComButton (props) {
  return (<CircleButtonWrapper flexDirection='column' alignItems='center'>
    <CircleButton />
    {props.children}
  </CircleButtonWrapper>)
}

const WrappedButton = HOCWrapper(GradientBorderButton)
const WrappedLabel = HOCWrapper(ButtonLabel)

export { WrappedButton as Button, WrappedLabel as ButtonLabel, ComButton }
