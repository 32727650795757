// @flow
import * as React from 'react'
import { AwilixContainer } from 'awilix'
import Transport from 'transport'
import UserStore from './user.store'
import AppStore from './app.store'

const ComposerContext = React.createContext<AwilixContainer|null>(null)

function ComposerProvider ({ composer, ...props }: {composer: AwilixContainer}) {
  return <ComposerContext.Provider value={composer} {...props} />
}

function useService<ServiceType> (service: string) {
  const context = React.useContext(ComposerContext)
  if (!context) {
    throw new Error(`useService must be used within a ComposerProvider`)
  }
  return context.resolve<ServiceType>(service)
}

function useTransport (): Transport {
  return useService<Transport>('transport')
}

function useUser (): UserStore {
  return useService('user')
}

function useApplication (): AppStore {
  return useService('application')
}

export { ComposerProvider, useService, useTransport, useUser, useApplication, ComposerContext }
