import React, { Component } from 'react'
import { Button } from '../Button'
import { ComposerContext } from '../../stores/composer.context'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { error: null, eventId: null }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ error })
    // TODO
    if (process.NODE_ENV === 'todo') {
      console.error(error, errorInfo)
    } else {
      this.props.sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        const eventId = this.props.sentry.captureException(error)
        this.setState({ eventId })
      })
    }
  }

  render () {
    if (this.state.error) {
      // render fallback UI
      return (
        <Button variant='primary' onClick={() => this.props.sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

function ContextError (props) {
  return (
    <ComposerContext.Consumer>
      {composer => (
        <ErrorBoundary sentry={composer.resolve('sentry')} {...props} />
      )}
    </ComposerContext.Consumer>
  )
}

export default ContextError
