// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import Composer from './CompositionRoot'
import App from './App'

const log = Composer.resolve('log').logger

const render = () => {
  const root = document.getElementById('app')
  if (root !== null) {
    ReactDOM.render(<App />, root)
  }
}

render()

if (module.hot) {
  module.hot.dispose(function () {
    log.debug('dispose', arguments)
    // module is about to be replaced
  })
  module.hot.accept(function () {
    // module or one of its dependencies was just updated
    log.debug('accept', arguments)
  })
}
