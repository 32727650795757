// @flow
import ReactGA from 'react-ga'
import Mixpanel from 'mixpanel-browser'

export default class Analytics {
  constructor ({ config }) {
    ReactGA.initialize(config.googleAnalytics, { debug: process.env.NODE_ENV === 'development' })
    Mixpanel.init(config.mixpanel)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  get _GA () {
    return ReactGA
  }

  setUser (userId: string) {
    this._GA.set({ userId })
    Mixpanel.identify(userId)
  }

  set (data: {}) {
    this._GA.set(data)
  }

  pageview (path: string) {
    this._GA.pageview(path)
    Mixpanel.track('Pageview', { path })
  }

  modalview (path: string) {
    this._GA.modalview(path)
    Mixpanel.track('Modalview', { path })
  }

  event (category: string, action: string, label?: string, value?: number, nonInteraction?: boolean) {
    let data = {
      category,
      action
    }
    if (label) data.label = label
    if (value) data.value = value
    if (nonInteraction) data.nonInteraction = nonInteraction
    this._GA.event(data)
    Mixpanel.track('Event', { data })
  }
}
