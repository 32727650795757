// @flow

import { createContainer, asClass, asValue } from 'awilix'
import config from './config'
import Logger from './Utils/logger'
import Transport from './transport'
import EventEmitter from 'eventemitter3'
import UserStore from './stores/user.store.js'
import AppStore from './stores/app.store.js'
import ChatStore from './stores/chat.store'
import MessageStore from './stores/messaging.store'
import ChallengeChat from './stores/challengeChat.store'
import Sentry from './Utils/Sentry'
import Analytics from './Utils/Analytics'

const container = createContainer()

container.register({
  config: asValue(config),
  log: asClass(Logger).singleton(),
  analytics: asClass(Analytics).singleton(),
  sentry: asClass(Sentry).singleton(),
  events: asClass(EventEmitter).singleton(),
  user: asClass(UserStore).singleton(),
  application: asClass(AppStore).singleton(),
  chat: asClass(ChatStore).singleton(),
  message: asClass(MessageStore).singleton(),
  challengeChat: asClass(ChallengeChat)
})

if (process.env.USE_MOCK === '1') {
  const TransportMock = require('./Utils/mocks/testTransport')
  container.register('transport', asClass(TransportMock.default).singleton())
} else {
  container.register('transport', asClass(Transport).singleton())
}

export default container
