// @flow
import * as React from 'react'
import { useService } from './composer.context'

type AnalyticsContextType = {
  children: React.Node
}

const AnalyticsContext = React.createContext<AnalyticsContextType | typeof undefined>(undefined)

function AnalyticsContextProvider ({ children }: AnalyticsContextType) {
  const Analytics = useService('analytics')
  return <AnalyticsContext.Provider value={Analytics}>{children}</AnalyticsContext.Provider>
}

function getAnalyticsContext () {
  const context = React.useContext(AnalyticsContext)
  if (!context) {
    throw new Error('getAnalyticsContext must be used within a AnalyticsContextProvider')
  }
  return context
}

function pageView (path?: string) {
  const analytics = getAnalyticsContext()
  if (!path) path = global.location.pathname + global.location.search
  React.useEffect(() => {
    analytics.pageview(path)
  }, [path])
}

function setUser (userId: string) {
  const analytics = getAnalyticsContext()
  React.useEffect(() => {
    analytics.setUser(userId)
  }, [])
}

function registerEvent (category: string, action: string, label?: string, value?: number, nonInteraction?: boolean) {
  const analytics = getAnalyticsContext()
  React.useEffect(() => {
    analytics.event(category, action, label, value, nonInteraction)
  }, [category, action, label, value, nonInteraction])
}

export { AnalyticsContextProvider, pageView, setUser, registerEvent }
