// globals
'use strict'
import GameSparks from './Utils/gamesparks'
import { GsNonce } from './Utils/fetch'
import type EventEmitter from 'eventemitter3'
import { log as logger } from '@fontanus/logger'
import type { ToastData, PlayerType } from './types'
import { AnyARecord } from 'dns'

const log = logger.getLogger('com-webapp.transport')

const noop = (message: object) => message

class ErrorNotification extends Error {
  notification: {}
  constructor(notification: { title: string, message: string, data: Object, type: 'error' }) {
    super('Notification')
    this.notification = notification
  }
}

/**
 * Generic GameSparks error message
 */
class ErrorWithData extends Error {
  data: {}
  constructor(message: string = 'GameSparks error response', data: Object) {
    super(message)
    this.data = data
  }
}

interface Response {
  error?: Object,
  '@class': '.MatchFoundMessage' |
  '.ChallengeStartedMessage' |
  '.ChallengeIssuedMessage' |
  '.ChallengeTurnTakenMessage' |
  '.ChallengeWonMessage' |
  '.ChallengeLostMessage' |
  '.ChallengeDrawnMessage' |
  '.MatchNotFoundMessage' |
  '.ScriptMessage' |
  '.TeamChatMessage' |
  '.ChallengeChatMessage' |
  '.SessionTerminatedMessage',
  messageId: string,
  dismissedMessage?: boolean,
  scriptData: Object,
}

interface DataResponse extends Response {
  data: {
    eventKey: 'GET_GAME_DATA'
  }
}

interface AiDataResponse extends DataResponse {
  nextPlayer: string,
  challengeId: string,
  state: string,
  lastAction: Date
}

interface CurrentPlayerResponse extends Response {
  newPlayer?: boolean,
  scriptData: PlayerType
}

interface PlayerResponse extends Response {
  scriptData: {
    player: PlayerType,
    user: PlayerType
  }
}

interface BoardsResponse extends Response {
  scriptData: {
    data: Array<{
      gameType: string
    }>
  }
}

interface RegisterBoardResponse extends Response {
  scriptData: {
    tokenData: {
      gameType: string
    }
  }
}

interface Challenge {
  challengeId: string,
  challengeMessage: string,
  nextPlayer: string,
  scriptData: {
    action?: Object,
    state: string,
    lastAction?: Object,
    winner?: string,
    scores?: Object
  }
}

interface StartChallengeResponse extends Response {
  challengeInstanceId: string
}

interface ChallengeResponse extends Response {
  challenge: Challenge
}

interface ListChallengeResponse extends Response {
  challengeInstances: Challenge[]
}

interface GameData { }

interface GameDataResponse extends Response {

}

interface ChallengeEndedResponse extends Response {
  scriptData: {
    gamestats: Object,
  },
  challenge: Challenge
}

interface SearchUserResponse extends Response {
  scriptData: {
    players: PlayerType[]
  }
}

interface TeamResponse extends Response {
  scriptData: {
    players: PlayerType[]
  }
}

interface LeaderBoardData {
  data: Object,
  first?: number,
  last?: number,
  players?: PlayerType[]
}

interface LeaderBoardResponse extends Response {
  data: Object,
  first: number,
  last: number,
  scriptData: {
    players: PlayerType[]
  }
}

interface ChatMessagesResponse extends Response {
  messages: Array<Object>,
  scriptData: {
    data: Object
  }
}

/**
 * The Transport class is responsible for every Client - GameSparks `ws` based communication
 */
class Transport { // eslint-disable-line camelbreak
  transport: GameSparks
  events: EventEmitter
  allowedBoards: Array<string> = []

  /**
   * Starts a new Transport. It's likely a singleton.
   *
   * @constructor
   * @memberof module:tranport
   * @param {object} dependency_injected_params
   */
  constructor({ config, events }: { config: Object, events: EventEmitter }) {
    this.events = events
    this.transport = new GameSparks()

    let initMethod: 'initLive' | 'initPreview' = 'initLive'
    // TODO
    if (process.env.NODE_ENV === 'todo') {
      initMethod = 'initPreview'
    }
    const conf = config.GameSparks
    conf.onNonce = GsNonce
    this.events.on('logout', () => {
      window.sessionStorage.removeItem('GS')
    })
    const session = window.sessionStorage.getItem('GS')
    if (session) {
      const { authToken, sessionId } = JSON.parse(session)
      this.transport.setAuthToken(authToken)
      this.transport.sessionId = sessionId
    }
    conf.onInit = (userId: string) => {
      log.info('GameSparks initialized', initMethod)
      const connectionFinished = () => events.emit('connected')
      if (userId) {
        // We use setTimeout to hope for all other event listeners started are already over
        this.getCurrentUserDetails(connectionFinished)
      } else {
        connectionFinished()
      }
    }
    conf.onError = () => {
      events.emit('reload')
    }
    conf.onClose = () => {
      events.emit('reload')
    }
    conf.onMessage = this._wrapHandler(this.defaultMessageHandler.bind(this), log.debug, log.error)
    conf.logger = log
    this.transport[initMethod](conf)

    this.allowedBoards = []
  }

  /**
   * Wrapper to resolve promises using a handler defined by the original request method
   * @param {Function} handler Called with message on success to resolve the promise
   * @param {Function} resolve Promise.resolve
   * @param {Function} reject Promise.reject
   */
  _wrapHandler<FinalType, GameSparksResponse>(handler: (message: GameSparksResponse) => FinalType, resolve: (value: FinalType) => void, reject: (err: Error) => void): (message: GameSparksResponse) => void {
    return (message: GameSparksResponse) => {
      if (message.error === 'NO_RESPONSE') {
        reject(new Error('No response from backend'))
      }
      try {
        resolve(handler(message))
      } catch (err) {
        log.error('Could not process event response', err)
        reject(err)
      }
    }
  }

  /**
   * Wrapper to resolve LogEvent and LogChallengeEvent promises using a handler bsed on the response scriptData
   * @param {Function} handler Called with message on success to resolve the promise
   * @param {Function} resolve Promise.resolve
   * @param {Function} reject Promise.reject
   */
  _wrapEventHandler<FinalType, GameSparksResponse>(handler: (message: GameSparksResponse) => FinalType, resolve: (message: FinalType) => void, reject: (err: Error) => void): (message: GameSparksResponse) => void {
    return (message: GameSparksResponse) => {
      if (message.error) {
        const error = new ErrorWithData('GameSparks error response', message.error)
        reject(error)
      } else {
        try {
          resolve(handler(message))
        } catch (err) {
          log.error('Could not process event response', err)
          reject(err)
        }
      }
    }
  }

  /**
   * The main messaging method.
   *
   * Sends a request as a Promise and resolves the response using a handler callback
   */
  sendRequest<FinalType, GameSparksResponse>(type: string, data: {}, handler: (message: GameSparksResponse) => FinalType): Promise<FinalType> {
    return new Promise((resolve, reject) => {
      let _handler: (message: GameSparksResponse) => void
      if (['LogEventRequest', 'LogChallengeEventRequest'].indexOf(type) === -1) {
        _handler = this._wrapHandler<FinalType, GameSparksResponse>(handler, resolve, reject)
      } else {
        _handler = this._wrapEventHandler<FinalType, GameSparksResponse>(handler, resolve, reject)
      }
      this.transport.sendWithData(type, data, _handler)
    })
  }

  defaultMessageHandler(message: Response) {
    const messageRegex = /.*Message$/
    const handlers = {
      '.MatchFoundMessage': this.handleMatchFoundMessage.bind(this),
      '.ChallengeStartedMessage': this.handleChallengeStartedMessage.bind(this),
      '.ChallengeIssuedMessage': this.handleChallengeIssuedMessage.bind(this),
      '.ChallengeTurnTakenMessage': this.handleChallengeTurnTakenMessage.bind(this),
      '.ChallengeWonMessage': this.handleChallengeWonMessage.bind(this),
      '.ChallengeLostMessage': this.handleChallengeLostMessage.bind(this),
      '.ChallengeDrawnMessage': this.handleChallengeDrawnMessage.bind(this),
      '.MatchNotFoundMessage': this.handleMatchNotFoundMessage.bind(this),
      '.ScriptMessage': this.scriptMessageHandler.bind(this),
      '.TeamChatMessage': this.handleTeamChatMessage.bind(this),
      '.ChallengeChatMessage': this.handleChallengeChatMessage.bind(this),
      '.SessionTerminatedMessage': this.handleLogOut.bind(this)
    }
    const keptMessages = ['.MatchNotFoundMessage', '.ChallengeWonMessage', '.ChallengeLostMessage', '.ChallengeDrawnMessage', '.NewHighScoreMessage', '.GlobalRankChangedMessage', '.SocialRankChangedMessage']
    if (message.messageId && !keptMessages.includes(message['@class'])) {
      message.dismissedMessage = true
      this._dismissMessage(message.messageId)
    }

    if (handlers[message['@class']]) {
      return handlers[message['@class']](message)
    } else if (messageRegex.test(message['@class'])) {
      this.handleSimpleNotificationMessage(message)
    }
  }

  scriptMessageHandler(message: DataResponse) {
    const handlers = {
      'GET_GAME_DATA': this.handleGameDataMessage.bind(this),
      'MOVE_PAWN': this.handleChallengeTurnTakenMessage.bind(this),
      'ATTACK_PAWN': this.handleChallengeTurnTakenMessage.bind(this)
    }

    if (handlers[message.data.eventKey]) {
      return handlers[message.data.eventKey](message.data)
    } else {
      const err = new ErrorWithData('Unhandled GameSparks Script message', message.data.eventKey)
      throw err
    }
  }

  /**
   * AcountDetailsRequest: `sessionId`-s bejelentkezés esetén lekérjük a felhasználó adatait
   *
   * @param {callback} onSuccess Callback to be called with `response.sciptData`
   */
  getCurrentUserDetails(onSuccess: (scriptData: Object) => void) {
    const data = {
    }
    return this.sendRequest<PlayerType, CurrentPlayerResponse>('AccountDetailsRequest', data, message => {
      this.events.emit('login', { user: message.scriptData })
      onSuccess(message.scriptData)
      return message.scriptData
    })
  }

  /**
   * LogEventRequest#GET_PLAYER_DATA: játék végén ezzel kérjük le mindkét játékos friss adatait
   *
   * Hogyan kezeljük a felületen? Frissítjük a játékosok adatait.
   *
   * @param {string} userId User id
   */
  updateUserDetails(userId: string) {
    const data = {
      'eventKey': 'GET_PLAYER_DATA',
      userId
    }
    return this.sendRequest<PlayerType, PlayerResponse>('LogEventRequest', data, message => {
      this.events.emit('updateFriend', message.scriptData.player)
      return message.scriptData.player
    })
  }

  /**
   * LogEventRequest#LOGOUT: Kilépéskor a szerveroldalon a session törlése
   *
   * Hogyan kezeljük a felületem? hatására megszűnik a `ws` kapcsolat, és újratöltjük az oldalt
   */
  logout() {
    const data = {
      'eventKey': 'LOGOUT'
    }
    return this.sendRequest<void, Response>('LogEventRequest', data, noop)
  }

  handleLogOut() {
    window.location.href = '/'
  }

  registerUserByName(nick: string, email: string, password: string) {
    const data = {
      userName: email,
      displayName: nick,
      password: password
    }
    return this.sendRequest<Promise<PlayerType>, CurrentPlayerResponse>('RegistrationRequest', data, this.factoryHandleRegistrationResponse(email, password))
  }

  loginUserByName(userName: string, password: string) {
    const data = {
      userName: userName,
      password: password
    }
    return this.sendRequest<PlayerType, CurrentPlayerResponse>('AuthenticationRequest', data, this.handleAuthenticationResponse.bind(this))
  }

  loginWithFacebook(_data: { accessToken: string, email: string, picture: { data: { url: string } } }) {
    const data = {
      accessToken: _data.accessToken
      // doNotCreateNewPlayer: true,
      // switchIfPossible: true
    }
    return this.sendRequest<Promise<PlayerType>, CurrentPlayerResponse>('FacebookConnectRequest', data, async message => {
      if (message.newPlayer) {
        // update the userName to the e-mail address of the user
        const data2 = {
          userName: _data.email
        }
        await this.sendRequest('ChangeUserDetailsRequest', data2, noop)
        const updatedUser = await this.setProfileImage(_data.picture.data.url)
        message.scriptData = updatedUser
      }
      return this.handleAuthenticationResponse(message)
    })
  }

  // loginWithGoogle (_data) {
  //
  //   const data = {
  //     accessToken: _data.accessToken,
  //     displayName: _data.profileObj.name // needed for GooglePlayConnectRequest
  //     // doNotCreateNewPlayer: true,
  //     // switchIfPossible: true
  //   }
  //   return this.sendRequest('GooglePlayConnectRequest', data, (message: Response) => {
  //     if (message.newPlayer) {
  //       // update the userName to the e-mail address of the user
  //       const data2 = {
  //         userName: _data.profileObj.email
  //       }
  //       this.setProfileImage(_data.profileObj.imageUrl)
  //       return this.sendRequest('ChangeUserDetailsRequest', data2, noop)
  //     }
  //     return this.handleAuthenticationResponse(message: Response)
  //   })
  //   // this.sendRequest('FacebookConnectRequest', data, partialRight(this.handleFacebookConnectResponse, _data, isSecondTry))
  // }

  /**
   * Handle a facebook login/registration request
   *
   * We would like to assure that each user can have their
   * login/password and facebook account linked to a single player.
   * This needs extra work when a user would like to log in with facebook.
   * 1. we try to log in, but restrict creating a new user
   * 2. if login failed, then we try to create a new user with a random password
   * 3.a. if this worked, we log the user in
   * 3.b. if this failed, then the e-mail is already registered, we ask the user to log in with it
   *
   * In 3.b. the user should be able to connect their FB account to the actual player account once logged in.
   *
   * @param {AuthenticationResponse | RegistrationResponse} message
   * @param {object} fbData
   * @param {boolean} isSecondTry Set to true when called for the second time to avoid looping on error
   */
  // handleFacebookConnectResponse (message, fbData, isSecondTry) {
  //   if (message.error && message.error.doNotCreateNewPlayer) {
  //
  //     const data = {
  //       userName: fbData.email,
  //       displayName: fbData.name,
  //       password: randomPassword()
  //     }
  //     return this.sendRequest('RegistrationRequest', data, message => {
  //       if (isSecondTry) {
  //         this.sendErrorToDom('Login', {
  //           error: 'facebook-registration-failed'
  //         })
  //       } else if (message.error && message.error.USERNAME === 'TAKEN') {
  //         this.sendErrorToDom('Login', {
  //           error: 'facebook-email-registered'
  //         })
  //       } else {
  //         return loginWithFacebook(fbData, true)
  //       }
  //     })
  //   } else {
  //     if (isSecondTry) {
  //       return {
  //         newPlayer: true
  //       }
  //     }
  //     return this.handleAuthenticationResponse(message: Response)
  //   }
  // }

  handleAuthenticationResponse(message: CurrentPlayerResponse) {
    if (message.error) {
      throw new ErrorWithData('Authentication error', message.error)
    } else {
      const authToken = this.transport.getAuthToken()
      const sessionId = this.transport.getSessionId()
      window.sessionStorage.setItem('GS', JSON.stringify({ authToken, sessionId }))
      this.events.emit('login', {
        newPlayer: message.newPlayer,
        user: message.scriptData
      })
      return message.scriptData
    }
  }

  factoryHandleRegistrationResponse(username: string, password: string) {
    return (message: CurrentPlayerResponse) => {
      if (message.error) {
        throw new ErrorWithData('Login error', message.error)
      } else {
        return this.loginUserByName(username, password)
      }
    }
  }

  // Current user's related messaging
  getRegisteredBoards() {
    const data = {
      'eventKey': 'GET_REGISTERED_GAMES'
    }
    return this.sendRequest<{ boards: string[] }, BoardsResponse>('LogEventRequest', data, this.handleRegisteredBoards.bind(this))
  }

  registerToken(token: string) {
    const data = {
      'eventKey': 'REGISTER_TOKEN',
      'TOKEN': token
    }
    return this.sendRequest<{ boards: string[] }, RegisterBoardResponse>('LogEventRequest', data, this.handleTokenRegistered.bind(this))
  }

  handleRegisteredBoards(message: BoardsResponse) {
    this.allowedBoards = this.allowedBoards.concat(message.scriptData.data.map(d => d.gameType))
    return {
      boards: this.allowedBoards
    }
  }

  handleTokenRegistered(message: RegisterBoardResponse) {
    this.allowedBoards.push(message.scriptData.tokenData.gameType)
    return {
      boards: this.allowedBoards
    }
  }

  // Matchmaking and Game messaging
  /**
     * véletlen match:
     * MatchFoundMessage
     * GET_GAME_DATA
     * ChallengeStartedMessage
     *
     * meghívás:
     * meghívó: CreateChallengeResponse - ez le van kezelve promiseként
     * meghívott: ChallengeIssuedMessage
     * GET_GAME_DATA
     * ChallengeStartedMessage
     */
  startMatchmaking(boardName: string) {
    const data = {
      matchShortCode: boardName,
      skill: 0
    }
    return this.sendRequest<void, Response>('MatchmakingRequest', data, noop)
  }

  cancelMatchmaking(boardName: string) {
    const data = {
      matchShortCode: boardName,
      skill: 0,
      action: 'cancel'
    }
    return this.sendRequest<void, Response>('MatchmakingRequest', data, noop)
  }

  getChallenge(challengeId: string) {
    const data = {
      challengeInstanceId: challengeId
    }
    return this.sendRequest<Challenge, ChallengeResponse>('GetChallengeRequest', data, message => {
      return message.challenge
    })
  }

  listChallenges(states: Array<'WAITING' | 'RUNNING' | 'RECEIVED' | 'COMPLETE'>, page = 1, entryCount = 10) {
    const data = {
      states,
      entryCount,
      offset: 10 * (page - 1)
    }
    return this.sendRequest<Challenge[], ListChallengeResponse>('ListChallengeRequest', data, message => {
      return message.challengeInstances || []
    })
  }

  getGameData(challengeId: string) {
    const data = {
      challengeInstanceId: challengeId,
      eventKey: 'GET_GAME_DATA'
    }
    return this.sendRequest<GameData, GameDataResponse>('LogEventRequest', data, noop)
  }

  handleMatchNotFoundMessage(message: Response) {
    this.handleSimpleNotificationMessage(message)
    this.events.emit('MatchNotFoundMessage', message)
  }

  handleMatchFoundMessage(message: Response) {
    this.events.emit('MatchFoundMessage', message)
  }

  handleChallengeStartedMessage(message: ChallengeResponse) {
    this.events.emit('ChallengeStartedMessage', message.challenge)
  }

  handleChallengeIssuedMessage(message: ChallengeResponse) {
    if (message.challenge.challengeMessage === 'COM#PRIVATE') {
      this.events.emit('ChallengeIssuedMessage', message.challenge)
    }
  }

  handleChallengeTurnTakenMessage(message: ChallengeResponse) {
    if (message.challenge) {

      this.events.emit('ChallengeTurnTakenMessage', {
        nextPlayer: message.challenge.nextPlayer,
        challengeId: message.challenge.challengeId
      })
      this.handleSimpleNotificationMessage(message, { showOnly: message.challenge.nextPlayer })
      if (message.challenge.scriptData.action) {
        this.events.emit('ActionMessage', {
          action: message.challenge.scriptData.action,
          challengeId: message.challenge.challengeId,
          state: message.challenge.scriptData.state,
          lastAction: message.challenge.scriptData.lastAction
        })
      }
    } else {
      console.log('transport message ' message)
      this.events.emit('ChallengeTurnTakenMessage', {
        nextPlayer: message.nextPlayer,
        challengeId: message.challengeId
      })
      this.events.emit('ActionMessage', {
        action: message,
        challengeId: message.challengeId,
        state: message.state,
        lastAction: message.lastAction
      })
    }
  }

  // handleAiActionMessage(message: any) {
  //   console.log(message)
  //   this.events.emit('ChallengeTurnTakenMessage', {
  //     nextPlayer: message.nextPlayer,
  //     challengeId: message.challengeId
  //   })

  //   this.events.emit('ActionMessage', {
  //     action: message,
  //     challengeId: message.challengeId,
  //     state: message.state,
  //     lastAction: message.lastAction
  //   })
  // }

  handleGameDataMessage(data: GameData) {
    this.events.emit('GameDataMessage', data)
  }

  giveUp(challengeId: string) {
    const data = {
      'eventKey': 'GIVE_UP',
      'challengeInstanceId': challengeId
    }
    return this.sendRequest('LogChallengeEventRequest', data, noop)
  }

  offerDraw(challengeId: string) {
    const data = {
      'eventKey': 'OFFER_DRAW',
      'challengeInstanceId': challengeId
    }
    return this.sendRequest('LogChallengeEventRequest', data, noop)
  }

  acceptDraw(challengeId: string) {
    const data = {
      'eventKey': 'ACCEPT_DRAW',
      'challengeInstanceId': challengeId
    }
    return this.sendRequest('LogChallengeEventRequest', data, noop)
  }

  rejectDraw(challengeId: string) {
    const data = {
      'eventKey': 'REJECT_DRAW',
      'challengeInstanceId': challengeId
    }
    return this.sendRequest('LogChallengeEventRequest', data, noop)
  }

  handleChallengeWonMessage(message: ChallengeEndedResponse) {
    this.events.emit('ChallengeEndedMessage', {
      stats: message.scriptData.gamestats,
      winner: message.challenge.scriptData.winner,
      scores: message.challenge.scriptData.scores,
      status: message['@class'].slice(10, -7).toLowerCase(),
      challengeId: message.challenge.challengeId,
      action: message.challenge.scriptData.action
    })
    this.handleSimpleNotificationMessage(message)
  }

  handleChallengeDrawnMessage(message: ChallengeEndedResponse) {
    this.events.emit('ChallengeEndedMessage', {
      stats: message.scriptData.gamestats,
      winner: message.challenge.scriptData.winner,
      scores: message.challenge.scriptData.scores,
      status: message['@class'].slice(10, -7).toLowerCase(),
      challengeId: message.challenge.challengeId,
      action: message.challenge.scriptData.action
    })
    this.handleSimpleNotificationMessage(message)
  }

  handleChallengeLostMessage(message: ChallengeEndedResponse) {
    this.events.emit('ChallengeEndedMessage', {
      stats: message.scriptData.gamestats,
      winner: message.challenge.scriptData.winner,
      scores: message.challenge.scriptData.scores,
      status: message['@class'].slice(10, -7).toLowerCase(),
      challengeId: message.challenge.challengeId,
      action: message.challenge.scriptData.action
    })
    this.handleSimpleNotificationMessage(message)
  }

  startChallenge(userId: string) {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    const data = {
      'accessType': 'PRIVATE',
      'autoStartJoinedChallengeOnMaxPlayers': true,
      'challengeMessage': 'COM#PRIVATE',
      'challengeShortCode': 'GAME_BASIC',
      'endTime': tomorrow.toISOString(),
      'maxPlayers': 2,
      'minPlayers': 2,
      'silent': false,
      'usersToChallenge': [
        userId
      ]
    }
    return this.sendRequest<{ challengeId: string }, StartChallengeResponse>('CreateChallengeRequest', data, message => {
      return {
        challengeId: message.challengeInstanceId
      }
    })
  }

  acceptChallenge(challengeId: string) {
    const data = {
      'challengeInstanceId': challengeId
    }
    return this.sendRequest<string, Response>('AcceptChallengeRequest', data, message => {
      if (message.error) {
        throw new ErrorNotification({
          title: 'challange.accept.failed.title',
          message: 'challange.accept.failed.body',
          data: message.error,
          type: 'error'
        })
      }
      return challengeId
    })
  }

  movePawn(challengeInstanceId: string, pawnIdx: number, toPositionIdx: number) {
    const data = {
      'eventKey': 'MOVE_PAWN',
      challengeInstanceId,
      pawnIdx,
      toPositionIdx
    }
    return this.sendRequest('LogChallengeEventRequest', data, (message: Response) => {
      return message
    })
  }

  attackPawn(challengeInstanceId: string, ennemyIdx: number) {
    const data = {
      'eventKey': 'ATTACK_PAWN',
      challengeInstanceId,
      ennemyIdx
    }
    return this.sendRequest('LogChallengeEventRequest', data, (message: Response) => {
      return message
    })
  }

  // Social features
  getCurrentGameData() {
    const data = {
      'eventKey': 'GET_ONLINE_PLAYERS'
    }
    return this.sendRequest('LogEventRequest', data, (message: Response) => {
      return message.scriptData
    })
  }

  searchUsers(name: string, email: string) {
    const data = {
      'eventKey': 'FIND_PLAYER',
      'name': name || '',
      'email': email || ''
    }
    return this.sendRequest<{ people: PlayerType[], error: Object | null }, SearchUserResponse>('LogEventRequest', data, message => {
      return {
        people: message.scriptData.players || [],
        error: message.error || null
      }
    })
  }

  getUserDetails(userId: string) {
    const data = {
      'eventKey': 'GET_PLAYER_DATA',
      userId
    }
    return this.sendRequest<PlayerType, PlayerResponse>('LogEventRequest', data, message => {
      return {
        error: message.error || null,
        ...message.scriptData.player
      }
    })
  }

  addAsFriend(userId: string) {
    const data = {
      'eventKey': 'ADD_FRIEND',
      'userId': userId
    }
    // TODO: test this
    return this.sendRequest('LogEventRequest', data, noop)
  }

  getFriendsList(pending: boolean) {
    const data = {
      'teamType': pending ? 'PENDING_FRIENDS_INCOMING' : 'FRIENDS_LIST'
    }
    return this.sendRequest<PlayerType[], TeamResponse>('GetTeamRequest', data, message => {
      return message.scriptData.players
    })
  }

  inviteFriend(email: string): Promise<boolean> {
    const data = {
      'eventKey': 'INVITE_PERSON',
      email
    }
    return this.sendRequest('LogEventRequest', data, (message: Response) => {
      return true
    })
  }

  acceptFriend(userId: string) {
    // TODO: test this
    this.addAsFriend(userId)
  }

  rejectFriend(userId: string) {
    // remove from the current user's PENDING_FRIENDS team

    const data = {
      'eventKey': 'REMOVE_PENDING_FRIEND',
      'friendId': userId
    }
    // TODO: test this
    return this.sendRequest('LogEventRequest', data, noop)
  }

  getLeaderboardData(data: Object) {
    return this.sendRequest<LeaderBoardData, LeaderBoardResponse>('LeaderboardDataRequest', data, message => {
      if (message.error) {
        throw new ErrorWithData('Error retrieving data', message.error)
      }
      return {
        data: message.data,
        first: message.first,
        last: message.last,
        players: message.scriptData.players
      }
    })
  }

  getLeaderboardDataForUser(userId: string, leaderboards = [
    'GAMES_PLAYED',
    'GAMES_SCORE',
    'GAMES_STATUS.status.winner',
    'GAMES_STATUS.status.looser',
    'GAMES_STATUS.status.drawn'
  ]) {
    const data = {
      leaderboards,
      'player': userId
    }
    return this.sendRequest<LeaderBoardData, LeaderBoardResponse>('GetLeaderboardEntriesRequest', data, message => {
      if (message.error) {
        throw new ErrorWithData('Error retrieving data', message.error)
      }
      return {
        data: message
      }
    })
  }

  chatWithUser(teamId: string) {
    const data = {
      teamId: teamId
    }
    return this.sendRequest<{ messages: Array<Object>, data: Object }, ChatMessagesResponse>('ListTeamChatRequest', data, message => {
      if (message.error) {
        throw new ErrorWithData('Could not get messages', message.error)
      }
      return {
        messages: message.messages,
        data: message.scriptData.data
      }
    })
  }

  sendChatMessage(teamId: string, message: string) {
    const data = {
      teamId,
      message
    }
    return this.sendRequest('SendTeamChatMessageRequest', data, noop)
  }

  updateLastRead(teamId: string, lastRead: number) {
    const data = {
      eventKey: 'CHAT_READ',
      teamId,
      lastRead
    }
    // TODO: test this
    return this.sendRequest('LogEventRequest', data, noop)
  }

  handleTeamChatMessage(message: Response) {
    this.events.emit('TeamChatMessage', message)
  }

  sendChallengeChat(challengeInstanceId: string, message: string) {
    const data = {
      challengeInstanceId,
      message
    }
    return this.sendRequest('ChatOnChallengeRequest', data, noop)
  }

  handleChallengeChatMessage(message: Response) {
    this.events.emit('ChallengeChatMessage', {
      message: message.message,
      who: message.who,
      fromId: message.scriptData.fromId,
      when: message.scriptData.when
    })
  }

  changeUser(data) {
    // TODO: test this
    return this.sendRequest('ChangeUserDetailsRequest', data, message => {
      if (message.error) {
        throw new ErrorWithData(message.error)
      }
      return this.getCurrentUserDetails(noop)
    })
  }

  getAzureSAS(extension: string) {
    const data = {
      extension,
      eventKey: 'GET_AZURE_SAS'
    }
    // TODO: test this
    return this.sendRequest('LogEventRequest', data, message => {
      return message.scriptData
    })
  }

  setProfileImage(profileImage: string) {
    const data = {
      profileImage,
      eventKey: 'USER_PROFILE'
    }
    // TODO: test this
    return this.sendRequest<PlayerType, PlayerResponse>('LogEventRequest', data, message => {
      return message.scriptData.user
    })
  }

  _formatMessage(message: any, status?: 'read' | 'unread' = 'unread', when?: number): ToastData {
    return {
      title: message.summary,
      options: {
        tag: message.messageId,
        timestamp: when,
        data: {
          status: status,
          message: message
        }
      },
      dismissedMessage: message.dismissedMessage || false
    }
  }

  listMessages(offset: number = 0) {
    const data = {
      entryCount: '10',
      offset
    }
    return this.sendRequest('ListMessageDetailRequest', data, response => {
      if (!response.messageList) {
        response.messageList = []
      }
      return response.messageList.map(m => this._formatMessage(m.message, m.status, m.when))
    })
  }

  getMessage(messageId: string) {
    return this.sendRequest('GetMessageRequest', { messageId }, message => {
      return this._formatMessage(message.message, message.status)
    })
  }

  markMessageRead(messageId: string) {
    const data = {
      messageId,
      status: 'read'
    }
    return this.sendRequest('UpdateMessageRequest', data, () => this.getMessage(messageId))
  }

  markMessagesRead(messageIds: Array<string>) {
    const data = {
      'eventKey': 'UpdateMessages',
      messageIds
    }
    return this.sendRequest('LogEventRequest', data, response => {
      return response.scriptData.messageList.map(m => this._formatMessage(m.message, m.status, m.when))
    })
  }

  _dismissMessage(messageId: string) {
    return this.sendRequest('DismissMessageRequest', { messageId }, noop)
  }

  handleSimpleNotificationMessage(message: Response, options = {}) {
    const notification = this._formatMessage(message)
    notification.options.data = {
      ...notification.options.data,
      ...options
    }
    this.events.emit('notification', notification)
  }
}

export default Transport
