// @flow

import { observable, computed, action } from 'mobx'

type MessageType = {
  id: string,
  message: string,
  fromId: string,
  who: string,
  when: Date
}

type ChallengeType = {
  challengeId: string,
  challenger: {
    id: string
  }
}

class Message {
  message: MessageType
  me: string

  constructor (me: string, message: MessageType) {
    this.me = me
    this.message = message
  }

  get isFromMe () {
    return this.me === this.message.fromId
  }
  get id () {
    return this.message.id
  }
  get text () {
    return this.message.message
  }
  get who () {
    return this.message.who
  }
  get when () {
    return this.message.when
  }
}

class UserChat {
  @observable loading: boolean = true
  @observable open: boolean = false
  @observable lastRead: Date | null = null
  @observable messages: Array<Message> = []
  @observable challenge : ChallengeType | null = null
  otherId: string
  mainStore: any
  teamId: string

  constructor (store: ChatStore, otherId: string) {
    this.mainStore = store
    this.otherId = otherId
    this.teamId = (() => {
      let tmp = [otherId, this.currentUser]
      tmp.sort()
      return tmp.join('-')
    })()
  }

  get otherName () : string {
    return this.mainStore.userStore.getFriend(this.otherId).displayName
  }

  @computed get currentUser () : string {
    return this.mainStore.userStore.user.userId
  }

  @computed get hasUnread () : boolean {
    if (this.lastRead === null && this.messages.length > 0) {
      return true
    } else if (this.lastRead === null) {
      return false
    }
    return this.messages.some(m => m.when > this.lastRead)
  }

  @action acceptChallenge () {
    if (this.challenge === null) return
    const challengeId = this.mainStore.transport.acceptChallenge(this.challenge.challengeId)
    this.challenge = null
    return challengeId
  }

  @action setMessages (messages: Array<MessageType>, data: {
    [string]: {
      lastRead: Date
    }
  }) {
    this.loading = false
    this.open = true
    this.lastRead = data[this.currentUser].lastRead
    messages.reverse()
    this.messages = messages.map(m => new Message(this.currentUser, m))
  }

  @action addMessage (message: MessageType) {
    message.when = message.fromId === this.currentUser ? this.lastRead : new Date()
    this.messages.push(new Message(this.currentUser, message))
  }

  close () {
    this.mainStore.deleteChat(this.teamId)
  }

  sendMessage (message: string) {
    this.mainStore.transport.sendChatMessage(this.teamId, message)
  }

  @action readMessages () {
    this.lastRead = new Date()
    return this.mainStore.transport.updateLastRead(this.teamId, this.lastRead)
  }
}

type MessagesByParty = {
  [string]: UserChat
}

class ChatStore {
  @observable messagesByParty : MessagesByParty = {}

  constructor ({ transport, events, user }) {
    this.transport = transport
    this.events = events
    this.userStore = user
    this.setupListeners()
    this.startChat = this.startChat.bind(this)
  }

  setupListeners () {
    this.events.on('logout', this.reset, this)
    this.events.on('TeamChatMessage', this.addMessage, this)
    this.events.on('ChallengeIssuedMessage', this.startChallenge, this)
  }

  getTeamId (otherId) {
    const _teamId = [otherId, this.userStore.user.userId]
    _teamId.sort()
    return _teamId.join('-')
  }

  @action deleteChat (teamId) {
    this.messagesByParty[teamId] = null
  }

  @action reset () {
    this.messagesByParty = {}
  }

  @action startChat (userId: string): UserChat {
    const chat = new UserChat(this, userId)
    this.messagesByParty[chat.teamId] = chat
    this.transport.chatWithUser(this.getTeamId(userId))
      .then(({ data, messages }) => this.messagesByParty[chat.teamId].setMessages(messages, data))
    return chat
  }

  @action addMessage (message: MessageType) {
    if (!this.messagesByParty[message.teamId]) {
      const chat = this.startChat(message.fromId)
      this.messagesByParty[message.teamId] = chat
    } else {
      this.messagesByParty[message.teamId].addMessage(message)
    }
  }

  startChallenge (challenge) {
    const teamId = this.getTeamId(challenge.challenger.id)
    this.startChat(challenge.challenger.id)
    this.messagesByParty[teamId].challenge = challenge
  }

  @action readMessage (fromId:string) {
    const teamId = this.getTeamId(fromId)
    this.messagesByParty[teamId].readMessages()
  }
}

export type { MessageType }
export { UserChat, Message }
export default ChatStore
