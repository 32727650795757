// @flow
import React from 'react'
import { Card } from 'rebass'
import ClipLoader from 'react-spinners/ClipLoader'

function Loading({ theme, size }: { theme: 'light' | 'dark' }) {
  return <Card variant={theme} style={{ background: 'transparent' }} p={3}>
    <ClipLoader sizeUnit={'px'}
      size={size}
      color='#123abc'
      loading />
  </Card>
}
Loading.defaultProps = {
  size: 150,
  theme: 'light'
}

export default Loading
