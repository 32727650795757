function createButtonVariant(colors) {
  return {
    fontFamily: 'Calendas-Plus',
    fontWeight: 'normal',
    color: `${colors.font || 'white'}!important`,
    borderRadius: '0.6em',
    backgroundColor: `${colors.base}!important`,
    '&:before': {
      backgroundColor: `${colors.baseBorder}`,
      boxShadow: `inset -16px -16px 6px -10px ${colors.baseBorderShadow}`
    },
    '&:hover': {
      backgroundColor: `${colors.hover}!important`
    },
    '&:hover:before': {
      backgroundColor: `${colors.hoverBorder}`,
      boxShadow: `inset -16px -16px 6px -10px ${colors.hoverBorderShadow}`
    }
  }
}

function createLoginButton(props) {
  return {
    ...createButtonVariant(props),
    paddingLeft: '32px!important',
    '&:after': {
      content: '""',
      boxSizing: 'border-box',
      position: 'absolute',
      zIndex: 2,
      top: 3,
      left: 0,
      width: '34px',
      height: '100%',
      background: `url('${props.url}') no-repeat`,
      backgroundSize: '16px 16px',
      backgroundPosition: '8px 1px'
    }
  }
}

function createBigButtonVariant(colors) {
  return {
    fontFamily: 'Calendas-Plus',
    fontWeight: 'normal',
    color: `${colors.font || 'white'}!important`,
    borderRadius: '1.3em!important',
    backgroundColor: `${colors.base}!important`,
    boxShadow: '-2px 0px 3px 0px rgba(0,0,0,0.75)',
    border: '0!important',
    '&:before': {
      backgroundColor: `${colors.baseBorder}`,
      boxShadow: `inset -16px -16px 6px -10px ${colors.baseBorderShadow}`,
      margin: '-2px!important'
    },
    '&:hover': {
      backgroundColor: `${colors.hover}!important`
    },
    '&:hover:before': {
      backgroundColor: `${colors.hoverBorder}`,
      boxShadow: `inset -16px -16px 6px -10px ${colors.hoverBorderShadow}`
    },
    padding: '15px 20px!important'
  }
}

function createImageButtonVariant(colors) {
  return {
    fontFamily: 'Calendas-Plus',
    fontWeight: 'normal',
    color: `${colors.font || 'white'}!important`,
    borderRadius: '0.5em!important',
    backgroundColor: `${colors.base}!important`,
    boxShadow: '-2px 0px 3px 0px rgba(0,0,0,0.75)',
    border: '0!important',
    '&:before': {
      backgroundColor: `${colors.baseBorder}`,
      boxShadow: `inset -16px -16px 6px -10px ${colors.baseBorderShadow}`,
      margin: '-2px!important'
    },
    padding: '0px!important'
  }
}

const theme = {}

theme.colors = {
  _devtest: '#3D6A11',
  darkerGrey99: '#8E8F92bb',
  darkerGrey77: '#8E8F9277',
  darkestGrey: '#4D4D4D',
  darkerGrey: '#8E8F92',
  darkGrey: '#999999',
  lighterBlack: '#777777',
  lightBlack: '#231f20',
  darkYellow: '#FBC332',
  blue: '#2C63AD',
  grey: '#BFBFBF',
  green: '#39AB23',
  red: '#FE0000',
  yellow: '#F3C752',
  lightBlue: '#007BBB',
  lightGreen: '#53F156',
  lightGrey: '#d9d9d9',
  lightGrey90: '#d9d9d990',
  lighterGrey: '#e5e5e5',
  magenta: '#836275',
  darkMagenta: '#6b4b5d'
}

theme.checkbox = {
  true: '#4285F4',
  false: 'white',
  focus: 'darkgrey'
}

theme.cards = {
  error: {
    color: 'red'
  },
  success: {
    color: 'green'
  },
  light: {
    color: '#000',
    backgroundColor: '#ccc'
  },
  dark: {
    color: '#fff',
    backgroundColor: '#000'
  }
}

theme.buttonColors = {
  green: {
    base: '#3AAC23',
    baseBorder: '#59B842',
    baseBorderShadow: '#2A7C18',
    hover: '#4ABC33',
    hoverBorder: '#69C852',
    hoverBorderShadow: '#3A8C28'
  },
  blue: {
    base: '#007BBB',
    baseBorder: '#2C91C5',
    baseBorderShadow: '#005581',
    hover: '#108BCB',
    hoverBorder: '#3CA1D5',
    hoverBorderShadow: '#106591'
  },
  yellow: {
    base: '#F9C531',
    baseBorder: '#FAD057',
    baseBorderShadow: '#A68321',
    hover: '#F5C127',
    hoverBorder: '#E6CC53',
    hoverBorderShadow: '#A27E1D',
    font: 'black'
  },
  grey: {
    base: '#9D9D9D',
    baseBorder: '#AFAEAD',
    baseBorderShadow: '#666666',
    hover: '#ADADAD',
    hoverBorder: '#BFBEBD',
    hoverBorderShadow: '#767676'
  },
  darkerGrey: {
    base: '#666666',
    baseBorder: '#7E7D7B',
    baseBorderShadow: '#4C4C4C',
    hover: '#9D9D9D',
    hoverBorder: '#AFAEAD',
    hoverBorderShadow: '#666666'
  },
  white: {
    base: '#eaeaea', // középső rész
    baseBorder: '#f1f1f1', // bal felső rész
    baseBorderShadow: '#a6a6a6', // jobb alsó shadow
    hover: '#fcfcfc',
    hoverBorder: '#f6f6f6',
    hoverBorderShadow: '#b9b9b9'
  },
  magenta: {
    base: '#836275',
    baseBorder: '#9b7a8f',
    baseBorderShadow: '#6b4b5d',
    hover: '#977588',
    hoverBorder: '#a9879c',
    hoverBorderShadow: '#826173'
  }
}

theme.buttons = {
  cursor: 'pointer',
  clean: {
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none'
  },
  raised: {
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.25)'
    }
  },
  icon: {
    ...createButtonVariant(theme.buttonColors.magenta),
    padding: '2px!important',
    display: 'flex',
    fontSize: '20px'
  },
  greyBig: createBigButtonVariant(theme.buttonColors.darkerGrey),
  image: createImageButtonVariant(theme.buttonColors.darkerGrey),
  grey: createButtonVariant(theme.buttonColors.grey),
  default: createButtonVariant(theme.buttonColors.magenta),
  primary: createButtonVariant(theme.buttonColors.blue),
  info: createButtonVariant(theme.buttonColors.yellow),
  green: createButtonVariant(theme.buttonColors.green),
  white: {
    ...createButtonVariant(theme.buttonColors.white),
    color: '#7d5569!important'
  },
  facebook: createLoginButton({
    base: '#4D63AD',
    baseBorder: '#9DA7CF',
    baseBorderShadow: '#465A9D',
    hover: '#495FA9',
    hoverBorder: '#99A3CB',
    hoverBorderShadow: '#425699',
    url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png'
  }),
  google: createLoginButton({
    base: '#DB4937',
    baseBorder: '#EC9C8E',
    baseBorderShadow: '#CC4433',
    hover: '#D74533',
    hoverBorder: '#E8988A',
    hoverBorderShadow: '#C8402F',
    url: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png'
  }),
  disabled: {
    ...createButtonVariant({
      base: 'lightgrey',
      baseBorder: 'darkgrey',
      baseBorderShadow: 'lightgrey',
      hover: 'lightgrey',
      hoverBorder: 'darkgrey',
      hoverBorderShadow: 'lightgrey'
    }),
    color: 'grey!important',
    cursor: 'not-allowed!important'
  },
  disabledBig: {
    ...createBigButtonVariant({
      base: 'lightgrey',
      baseBorder: 'darkgrey',
      baseBorderShadow: 'lightgrey',
      hover: 'lightgrey',
      hoverBorder: 'darkgrey',
      hoverBorderShadow: 'lightgrey'
    }),
    color: 'grey!important',
    cursor: 'not-allowed!important'
  },
  disabledWhite: {
    ...createButtonVariant({
      base: '#9c9c9c', // középső rész
      baseBorder: '#a0a0a0', // bal felső rész
      baseBorderShadow: '#8e8e8e', // jobb alsó shadow
      hover: '#9c9c9c',
      hoverBorder: '#a0a0a0',
      hoverBorderShadow: '#8e8e8e'

    }),
    color: '#d4d4d4!important',
    cursor: 'not-allowed!important',
  }
}

theme.link = {
  color: theme.colors.lightBlue
}

theme.transparent = {
  white20: `rgba(255,255,255,0.2)`,
  white40: `rgba(255,255,255,0.4)`,
  white50: `rgba(255,255,255,0.5)`,
  white60: `rgba(255,255,255,0.6)`,
  white70: `rgba(255,255,255,0.7)`,
  white80: `rgba(255,255,255,0.8)`,
}

theme.shadow = {
  medium: '0px 4px 8px 4px rgba(0, 0, 0, 0.08), 0px 1px 10px 2px rgba(0, 0, 0, 0.15)',
  big: '0px 4px 12px rgba(0, 0, 0, 0.15), 0px 0px 3px rgba(0, 0, 0, 0.2)',
  huge: '0px 8px 24px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15)',
};
theme.dropShadow = {
  extraSmall: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2))',
  small: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4))',
  medium: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.4))',
  large: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.8))',
}

theme.breakpoints = ['1025px', '1201px', '1801px']

theme.breakpoint = {
  xxl: 2560,
  xl: 1801,
  lg: 1201,
  md: 1025,
  sm: 840,
  xs: 440,
}

export default theme
