// TODO: move this to .env
/**
 * Application configuration
 * @module Configuration
 */
export default {
  logCollector: 'https://logcollector.fontanus.hu/',
  GameSparks: {
    key: 'e377116LcNPY',
    secret: 'dRrUttbEtgtk4Uccc7Yos7WqiSfr55dU'
  },
  googleAnalytics: 'UA-32835709-10',
  sentry: 'https://070494fdefbc458fb23e3800ce13c6b1@sentry.io/1451717',
  mixpanel: '9be70e1b72adaba89818b036b9f27e99',
  FacebookAppId: '508475319682605',
  GoogleClientId: '362217755351-fcvr4mugg6o0gnb06hok3f1st9g3d01c.apps.googleusercontent.com',
  version: `com-react-only`
}
