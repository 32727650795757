import * as loglevel from 'loglevel'
import * as logger from './logger'

interface Log extends loglevel.RootLogger {
  setupLogger: typeof logger.setupLogger;
  getSetupString: typeof logger.getSetupString;
}

export const log = loglevel as Log

log.setDefaultLevel(logger.getDefaultLogLevel())

// applying custom log handling
const originalLogFactory = log.methodFactory
log.methodFactory = logger.LoggerFactory(originalLogFactory)
log.setLevel(logger.getDefaultLogLevel(), true)
/**
 * @type {logger.setupLogger} Sets up the logger
 */
log.setupLogger = logger.setupLogger
/**
 * @type {logger.getSetupString} Retrieves the setup string for this logger
 */
log.getSetupString = logger.getSetupString
