import i18n from 'i18next'
import { setLocale } from 'yup'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { log } from '@fontanus/logger'
import translations from '../locales/*.json'
import FormatDuration from 'humanize-duration'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use({
    type: 'postProcessor',
    name: 'duration',
    process: function (value, key, options, translator) {
      if (options.postProcess !== 'duration') {
        return value
      }
      return FormatDuration(value, { language: translator.language, fallbacks: ['en'] })
    }
  })
  .init({
    // lng: 'hu',
    resources: translations,
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: process.env.NODE_ENV === 'development' ? 'dev' : 'en',
    interpolation: {
      escapeValue: false
    }
  }, (err, t) => {
    if (err) {
      log.error('i18n init error', err)
      return
    }
    setLocale({
      mixed: {
        default: t('validation.default'),
        required: '',
        oneOf: t('validation.oneOf')
      },
      string: {
        emai: t('validation.string.email')
      }
    })
  })

export default i18n
