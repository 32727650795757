// @flow
import { observable, action, computed, runInAction } from 'mobx'
import i18n from '../i18n'
import log from '@fontanus/logger'

export default class UserStore {
  @observable user = null
  @observable isNewUser = false
  @observable boards = null
  @observable friends = []
  @observable pendingFriends = []


  constructor({ sentry, transport, events, analytics }) {
    this.sentry = sentry
    this.transport = transport
    this.events = events
    this.analytics = analytics
    this.withUser = this.withUser.bind(this)
    this.enrichedUser = this.enrichedUser.bind(this)

    this.events.on('logout', this.reset, this)
    this.events.on('login', this.afterLogin, this)
    this.events.on('updateFriend', this.updateFriend, this)
    this.setFriends = this.setFriends.bind(this)
    this.setPendingFriends = this.setPendingFriends.bind(this)
  }

  startTimer() {
    // TODO
    if (process.env.NODE_ENV !== 'todo') {
      if (this.friendsInterval) {
        window.clearInterval(this.friendsInterval)
      }
      this.friendsInterval = window.setInterval(() => {
        this.transport.getFriendsList(false).then(this.setFriends)
        this.transport.getFriendsList(true).then(this.setPendingFriends)
      }, 5000)
    }
    this.transport.getFriendsList(false).then(this.setFriends)
    this.transport.getFriendsList(true).then(this.setPendingFriends)
  }

  @action setFriends(friends) {
    this.friends = friends.filter(f => f.userId !== this.user.userId)
  }

  @action setPendingFriends(friends) {
    this.pendingFriends = friends.filter(f => f.userId !== this.user.userId)
  }

  @action reset() {
    window.clearInterval(this.friendsInterval)
    this.user = null
    this.boards = null
    this.friends = []
    this.pendingFriends = []
    this.sentry.logout()
    this.analytics.setUser(null)
  }

  @computed get isAuthenticated() {
    return !!(this.user && this.user.userId)
  }

  @computed get boardsLoaded() {
    return Array.isArray(this.boards)
  }

  @computed get hasBoards() {
    return this.boardsLoaded && this.boards.length > 0
  }

  @action updateUser() {
    this.transport.updateUserDetails(this.user.userId)
      .then(user => this.user = user)
  }

  @computed get sortedFriends() {
    // sort by online status, then by name
    const friends = this.friends.slice(0)
    friends.sort((a, b) => {
      if (a.online && !b.online) {
        return -1
      } else if (!a.online && b.online) {
        return 1
      } else {
        return a.displayName < b.displayName ? -1 : 1
      }
    })
    return friends
  }

  @computed get onlineFriends() {
    return this.sortedFriends.filter(f => f.online)
  }

  @computed get offlineFriends() {
    return this.sortedFriends.filter(f => !f.online)
  }

  @action setUser(user) {
    this.user = user
    this.sentry.setUser(user.userId, user.userName)
    this.analytics.setUser(user.userId)
  }

  async login(values) {
    return this.transport.loginUserByName(values.email, values.password)
  }

  async afterLogin(userData) {
    if (!userData) return

    this.setUser(userData.user)

    const { boards } = await this.transport.getRegisteredBoards()
    runInAction(() => {
      this.boards = boards
    })

    this.startTimer()

    return userData
  }

  getFriend(friendId) {
    const found = this.friends.find(friend => friend.userId === friendId)
    if (found) {
      return found
    } else {
      return false
    }
  }

  getPendingFriend(friendId) {
    const found = this.pendingFriends.find(friend => friend.userId === friendId)
    if (found) {
      return found
    } else {
      return false
    }
  }

  async getUser(userId) {
    const user = this.getFriend(userId) || this.getPendingFriend(userId)
    if (user) {
      return user
    }

    return this.transport.getUserDetails(userId)
  }

  @action updateFriend(friendData) {
    const user = this.getFriend(friendData.userId) || this.getPendingFriend(friendData.userId)
    if (user) {
      user.score = friendData.score
    }
  }

  withUser(user, action) {
    const actions = {
      'add-friend': this.transport.addAsFriend.bind(this.transport),
      'accept-invite': this.transport.acceptFriend.bind(this.transport),
      'reject-invite': this.transport.rejectFriend.bind(this.transport),
      'play': this.transport.startChallenge.bind(this.transport)
    }

    if (actions[action]) {
      return actions[action](user.userId)
    }
    throw new Error(`No user action: ${action}`)
  }

  enrichedUser(user) {
    return {
      ...user,
      isFriend: !!this.friends.find(u => u.userId === user.userId),
      isPending: !!this.pendingFriends.find(u => u.userId === user.userId),
      status: user.online
        ? user.isPlaying
          ? 'playing'
          : 'available'
        : 'offline'
    }
  }

  findFriends(name) {
    if (name.length === 0) {
      return []
    }
    const regex = new RegExp('\\b' + name, 'i')
    return this.friends.filter(f => regex.test(f.displayName.toLowerCase()))
  }
}
