// @flow
import React, { Suspense, lazy, useEffect, useCallback } from 'react'
import './assets'
import './i18n'
import { useMediaQuery } from 'react-responsive'
import { observer } from 'mobx-react-lite'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from 'styled-components'
import { ComposerProvider } from './stores/composer.context'
import theme from './theme'
import Error from './Components/Error'
import Loading from './Components/Loading'
import Composer from './CompositionRoot'
import '../assets/style.css'

const DesktopApp = lazy(() => import('./DesktopApp'))

const AnalyticsContextProvider = lazy(() => import('./stores/analytics.context')
  .then(module => ({ default: module.AnalyticsContextProvider }))
)

function App() {
  const history = useCallback(createBrowserHistory(), [])
  const appStore = Composer.resolve('application')
  const transport = Composer.resolve('transport')

  // We need to initialize the Message store to register some event listeners
  Composer.resolve('message')

  const unloader = useCallback(() => {
    transport.logout()
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', unloader)
    return () => {
      window.removeEventListener('beforeunload', unloader)
    }
  }, [])

  return (
    <ComposerProvider composer={Composer}>
      <ThemeProvider theme={theme}>
        {appStore.isConnected
          ? (
            <Router history={history}>
              <Error>
                <Suspense fallback={<Loading />}>
                  <AnalyticsContextProvider>
                    <DesktopApp appStore={appStore} />
                  </AnalyticsContextProvider>
                </Suspense>
              </Error>
            </Router>
          )
          : (<Loading />)}
      </ThemeProvider>
    </ComposerProvider>
  )
}

export default observer(App)
